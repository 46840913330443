import u from './Util';
import TextConfirm from  './utils/TextConfirm';
import _ from './utils/I18n';

u.sub('.revoke-mod2inv', 'click', function(e){
  var user=this.getAttribute('data-user');
  var nsub=this.getAttribute('data-sub');
  u.post('/do/revoke_mod2inv/'+nsub+'/'+user, {},
  function(data){
    if (data.status == "ok") {
      document.location.reload();
    }
  });
});

u.sub('#accept-mod2-inv', 'click', function(e){
  var user=this.getAttribute('data-user');
  var nsub=this.getAttribute('data-sub');
  u.post('/do/accept_modinv/'+nsub+'/'+user, {},
  function(data){
    if (data.status == "ok") {
      document.location.reload();
    }
  });
});

u.sub('#refuse-mod2-inv', 'click', function(e){
  var user=this.getAttribute('data-user');
  var nsub=this.getAttribute('data-sub');
  u.post('/do/refuse_mod2inv/'+nsub, {},
  function(data){
    if (data.status == "ok") {
      document.location = '/messages';
    }
  });
});

u.sub('.revoke-mod2', 'click', function(e){
  var user=this.getAttribute('data-user');
  var nsub=this.getAttribute('data-sub');
  TextConfirm(this, function(){
    u.post('/do/remove_mod2/'+nsub+'/'+user, {},
    function(data){
      if (data.status == "ok") {
        if(!data.resign){
          document.location.reload();
        }else{
          document.location = '/s/' + nsub;
        }
      }
    });
  });
});

u.sub('.revoke-ban', 'click', function(e){
  var user=this.getAttribute('data-user');
  var nsub=this.getAttribute('data-sub');
  u.post('/do/remove_sub_ban/'+nsub+'/'+user, {},
  function(data){
    if (data.status == "ok") {
      document.location.reload();
    }
  });
});

u.sub('button.blk,button.unblk,button.sub,button.unsub', 'click', function(e){
  var sid=this.parentNode.getAttribute('data-sid');
  var act=this.getAttribute('data-ac')
  u.post('/do/' + act + '/' + sid, {},
  function(data){
    if (data.status == "ok") {
      document.location.reload();
    }
  });
});

window.onkeydown = function(e){
  if(!document.getElementsByClassName('alldaposts')[0]){return;}
  if(e.shiftKey == true && e.which == 88){
    console.log('weew')
    window.expandall = true;
    u.each('div.post', function(t, i){
      var q = t.getElementsByClassName('expando-btn')[0]
      if(q && q.getAttribute('data-icon') == "image"){
        q.click()
      }
    });
  }
}

u.sub('#ban_timepick', 'change', function(e){
  document.querySelector('.date-picker-future.input').value = '';
  if(this.value == 'ban_temp'){
    document.querySelector('.date-picker-future.input').style.display = 'inline-block';
  }else{
    document.querySelector('.date-picker-future.input').style.display = 'none';
  }
});

u.sub('#change_user_flair', 'click', function(e) {
  e.preventDefault()
  document.getElementById('userflair').style.display = 'block';
})

u.sub('#userflair .selflair', 'click', function () {
    const flair = this.getAttribute('data-flair');
    const nsub = this.getAttribute('data-sub'), tg = this;
    u.post('/do/user_flair/' + nsub + '/' + flair, {},
        function (data) {
            if (data.status != "ok") {
                tg.parentNode.innerHTML = _('Error: %1', data.error);
            } else {
                tg.parentNode.innerHTML = _('Done!');
                document.location.reload();
            }
        }
    );
});
