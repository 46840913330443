import 'purecss/build/base.css';
import 'purecss/build/forms.css';
import 'purecss/build/buttons.css';
import 'purecss/build/grids.css';
import 'purecss/build/grids-responsive.css';
import 'purecss/build/menus.css';
import 'tingle.js/dist/tingle.css';
//import 'flatpickr/dist/flatpickr.css';
import 'flatpickr/dist/themes/dark.css';

import './polyfill.js';
import './ext/CustomElements.min.js';
import '@github/relative-time-element';
import u from './Util';
import Konami from './ext/konami';
import Sortable from 'sortablejs';
import flatpickr from "flatpickr";
import Tingle from 'tingle.js';
import _ from './utils/I18n';

window.Sortable = Sortable;
require('../css/main.css');
require('../css/dark.css');
require('../css/custom.css');
require('../css/ovarit.css');

import icons from './Icon';
require('./Expando');
require('./Post');
require('./Editor');
require('./Messages');
require('./Sub');
require('./Poll');
require('./Mod');
require('./Badge');
require('./Chat');

import socket from './Socket'
var modal = new Tingle.modal({});

// Show modal offering login or registration.
function show_login_modal() {
  modal.setContent('<h1>' + _('Log in or register to continue') + '</h1>\
    <div class="pure-g"> \
      <div class="pure-u-1-1 pure-u-lg-1-2">\
        <h3> Log in </h3> \
        <form id="modal-login-form" class="pure-form pure-form-aligned">\
          <fieldset> \
            <div class="pure-control-group"> \
              <input id="username" name="username" pattern="[a-zA-Z0-9_-]+" placeholder="' + _('Username') + '" required="" title="' + _('Alphanumeric characters plus \'-\' and \'_\'') + '" type="text"> \
            </div> \
            <div class="pure-control-group"> \
              <input id="password" name="password" placeholder="' + _('Password') + '" required type="password"> \
            </div> \
            <div class="pure-controls">\
              <label for="remember" class="pure-checkbox">\
              <input id="remember_me" name="remember" type="checkbox" value="y"> ' + _('Remember me') + ' \
              </label> \
              <div class="error" style="display:none" id="modal-error-message"></div>\
              <button type="submit" class="pure-button pure-button-primary">' + _('Log in') + '</button> \
            </div> \
            <br/> \
            <div> \
              <a href="/reset">' + _('Forgot your password?') + '</a>\
            </div> \
          </fieldset> \
        </form> \
      </div> \
      <div class="pure-u-1-1 pure-u-lg-1-2"> \
      <h3>Register</h3> \
      <p>' + _('Don\'t have an account?') + '</p> \
      <a class="pure-button pure-button-primary" href="/register">' + _('Register now!') + '</a>\
      </div> \
    </div> \
    ');
  const loginForm = document.getElementById("modal-login-form");
  const errorMessageContainer = document.getElementById('modal-error-message');

  loginForm.addEventListener("submit", async (e) => {
    e.preventDefault();

    const authenticate = {
      username: document.getElementById("username").value,
      password: document.getElementById("password").value,
      remember_me: document.getElementById('remember_me').checked
    };

    const response = await fetch("/ovarit_auth/authenticate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(authenticate)
    });

    if (response.ok) {
      window.location.reload()
    } else {
      errorMessageContainer.style.display = 'block';
      errorMessageContainer.textContent = "Error: " + response.statusText;
    }
  });

  modal.open();
}

function vote(obj, how, comment) {
  if (!document.getElementById('logout')) {
    show_login_modal();
    return;
  }

  var id = comment ? obj.parentNode.parentNode.parentNode.getAttribute('data-cid') : obj.getAttribute('data-pid');
  var count = comment ? obj.parentNode.parentNode.querySelector('.cscore') : obj.parentNode.querySelector('.score');

  if (!obj.getAttribute('data-in-progress')) {
    obj.setAttribute('data-in-progress', true);

    var existingVote = obj.classList.contains('upvoted') ? 'up' : obj.classList.contains('downvoted') ? 'down' : null;
    var voteDirection = existingVote === how ? null : how.toUpperCase();
    var variables = comment ? { cid: id, vote: voteDirection } : { pid: id, vote: voteDirection };

    u.post('/graphql', {
      query: comment ? 'mutation 7902b366b5' : 'mutation fbb43a8fe9',
      variables: variables
    }, function (data) {
      obj.removeAttribute('data-in-progress');
      if (!data.errors) {
        if (voteDirection) {
          obj.classList.add((how == 'up') ? 'upvoted' : 'downvoted');
          if (obj.parentNode.querySelector((how == 'up') ? '.downvoted' : '.upvoted')) {
            obj.parentNode.querySelector((how == 'up') ? '.downvoted' : '.upvoted').classList.remove((how == 'up') ? 'downvoted' : 'upvoted')
          }
        } else {
          obj.classList.remove((how == 'up') ? 'upvoted' : 'downvoted');
        }
        count.innerHTML = data.data[comment ? 'cast_comment_vote' : 'cast_post_vote'].score;
      }
    }, function (err) {
      obj.removeAttribute('data-in-progress');
    });
  }
}

// up/downvote buttons.
u.addEventForChild(document, 'mousedown', '.upvote,.downvote,.c-upvote,.c-downvote', function (e, target) {
  var upvote = (target.classList.contains('upvote') || target.classList.contains('c-upvote'))
  var comment = (target.classList.contains('c-upvote') || target.classList.contains('c-downvote'))
  vote(target, upvote ? 'up' : 'down', comment)
  e.preventDefault();
})


u.ready(function () {
  u.addEventForChild(document, 'change', 'input[type="file"]', function (e, target) {
    if (!window.FileReader) return;

    var input = target
    if (!input.files) {
      return;
    }
    else if (!input.files[0]) {
      return;
    } else {
      var file = input.files[0];
      var max = target.getAttribute('data-max');
      if (max && file.size > max) {
        alert(_("File size exceeds maximum allowed (%1 MB)", max / 1024 / 1024))
        target.value = '';
      }
    }
  })
  // initialize all date pickers
  flatpickr(".date-picker-future", {
    enableTime: true,
    dateFormat: 'Z',
    altInput: true,
    altFormat: 'Y-m-d H:i',
    time_24hr: true,
  });
  // Hide the Submit a post poll flatpickr initially.
  var cb = document.getElementById('closetime_date');
  if (cb && cb.nextElementSibling) {
    cb.nextElementSibling.style.display = 'none';
  }

  // for the top bar sorts
  var list = document.getElementById("subsort");
  if (list) {
    new window.Sortable(list, {
      animation: 100,
    });
  }

  u.sub('.save-top_bar', 'click', function (e) {
    var btn = this;
    btn.setAttribute('disabled', true);
    btn.innerHTML = _("Saving...");
    var subs = []
    u.each('.subsort_item', function (e) {
      subs.push(e.getAttribute('sid'))
    });
    u.post('/do/edit_top_bar', { sids: subs }, function (d) {
      if (d.status == "ok") {

      } else {
        alert(_('There was an error while saving your settings. Please try again in a few minutes.'));
      }
      btn.removeAttribute('disabled');
      btn.innerHTML = _("Save");
    }, function () {
      alert(_('There was an error while saving your settings. Please try again in a few minutes.'));
      btn.removeAttribute('disabled');
      btn.innerHTML = _("Save");
    })
  })

  document.querySelectorAll(".ajaxform").forEach((element) => {
    element.onSubmit = (event, target) => {
      event.preventDefault();
      console.log(event);
    };
  });

  // TODO: Get rid of this.
  u.addEventForChild(document, 'submit', '.ajaxform', function (e, target) {
    e.preventDefault();
    // This is a bad idea, but it only breaks removing badges for admins in safari, and it fixes some other things.
    // The SubmitEvent API isn’t supported in Safari, so work around it.
    var button = e.submitter || target.querySelector("[type=submit]");
    var btnorm = button.innerHTML;
    var data = new FormData(target);

    button.setAttribute('disabled', true);
    if (button.getAttribute('data-prog')) {
      button.innerHTML = button.getAttribute('data-prog');
    }
    let action = target.getAttribute('action')
    if (e.submitter && e.submitter.getAttribute("formaction")) {
      action = e.submitter.getAttribute("formaction");
    }
    u.rawpost(action, data,
      function (data) { // success
        if (data.status != "ok") {
          button.removeAttribute('disabled');
          let error = data.error;
          if (Array.isArray(data.error)) {
            error = data.error[0];
          }
          if (target.querySelector('.div-error')) {
            target.querySelector('.div-error').innerHTML = error;
            target.querySelector('.div-error').style.display = 'block';
          }
          if (target.querySelector('.div-message')) {
            target.querySelector('.div-message').style.display = 'none';
          }
          button.innerHTML = btnorm;
        } else { // success
          let message = data.message;
          if (Array.isArray(data.message)) {
            message = data.message[0];
          }
          if (target.querySelector('.div-message')) {
            target.querySelector('.div-message').innerHTML = message;
            if (message) {
              target.querySelector('.div-message').style.display = 'block';
            } else {
              target.querySelector('.div-message').style.display = 'none';
            }
          }
          if (target.querySelector('.div-error')) {
            target.querySelector('.div-error').style.display = 'none';
          }

          if (target.getAttribute('data-reset')) {
            target.reset();
          }
          if (button.getAttribute('data-success')) {
            button.innerHTML = button.getAttribute('data-success');
          }
          if (target.getAttribute('data-redir')) {
            if (target.getAttribute('data-redir') == "true") {
              document.location = data.addr;
            } else {
              document.location = target.getAttribute('data-redir');
            }
          } else if (target.getAttribute('data-reload')) {
            document.location.reload();
          } else {
            button.removeAttribute('disabled');
          }
        }
      }, function (data) { //error
        let error = _('Error while contacting the server');
        if (data.startsWith('{')) {
          let jsdata = JSON.parse(data);
          error = data.error;
          if (Array.isArray(jsdata.error)) {
            error = jsdata.error[0];
          }
        }
        if (target.querySelector('.div-error')) {
          target.querySelector('.div-error').innerHTML = error;
          target.querySelector('.div-error').style.display = 'block';
        }
        button.removeAttribute('disabled');
        button.innerHTML = btnorm;
      })
  });

  // Tag buttons with delete for a confirmation
  Array(...document.getElementsByClassName("delete")).forEach(element => {
    if (element.type !== "submit") {
      return;
    }
    element.onclick = (event) => {
      return confirm(_("Are you sure you want to delete?"));
    };
  });

});

// toggle dark mode
if (document.getElementById('toggledark')) {
  document.getElementById('toggledark').addEventListener('click', function () {
    var mode = getCookie("dayNight");
    var d = new Date();
    d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000)); //365 days
    var expires = "expires=" + d.toGMTString();
    var newMode = "light";
    if (mode == "dark" || mode == "dank") {
      document.cookie = "dayNight" + "=" + "light" + "; " + expires + ";path=/";
      document.getElementsByTagName('body')[0].classList.remove('dark');
      document.getElementsByTagName('body')[0].classList.remove('dank');
      document.querySelector('#toggledark span').innerHTML = icons.moon;
      //document.getElementById('chpop').style.display='none'
    } else {
      newMode = "dark";
      document.cookie = "dayNight" + "=" + "dark" + "; " + expires + ";path=/";
      document.getElementsByTagName('body')[0].classList.add('dark');
      document.querySelector('#toggledark span').innerHTML = icons.sun;
    }
    var funding = window.frames.fundingGoal;
    if (funding) {
      funding.postMessage(JSON.stringify({ "msgtype": "dayNight", "value": newMode }));
    }
  })
}
// TODO: move to util
function getCookie(cname) {
  var name = cname + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
if (document.getElementById('delete_account')) {
  document.getElementById('delete_account').addEventListener('click', function (e) {
    if (document.getElementById('delete_account').checked) {
      if (!confirm(_('Are you sure you want to PERMANENTLY delete your account?'))) {
        e.preventDefault()
        return false;
      }
    }
  })
}


// admin - remove banned domain
if (document.querySelector('button.removebanneddomain')) {
  u.addEventForChild(document, 'click', 'button.removebanneddomain', function (e, qelem) {
    var domain = qelem.getAttribute('data-domain');
    var domain_type = qelem.getAttribute('data-domain-type')
    u.post('/do/remove_banned_domain/' + domain_type + '/' + domain, {}, function (data) {
      if (data.status == "ok") {
        document.location.reload();
      }
    })
  });
}


/* purecss*/
var menu = document.getElementById('menu'),
  WINDOW_CHANGE_EVENT = ('onorientationchange' in window) ? 'orientationchange' : 'resize';
var sidebar = document.getElementById('sidebar');

function toggleHorizontal() {
  [].forEach.call(
    document.getElementById('menu').querySelectorAll('.can-transform'),
    function (el) {
      el.classList.toggle('pure-menu-horizontal');
    }
  );
};

function toggleMenu() {
  // set timeout so that the panel has a chance to roll up
  // before the menu switches states
  if (menu.classList.contains('open')) {
    setTimeout(toggleHorizontal, 500);
  }
  else {
    toggleHorizontal();
  }
  menu.classList.toggle('open');
  sidebar.classList.toggle('open')
  document.getElementById('toggle').classList.toggle('x');
};

function closeMenu() {
  if (menu.classList.contains('open')) {
    toggleMenu();
  }
}

document.getElementById('toggle').addEventListener('click', function (e) {
  toggleMenu();
  e.preventDefault();
});

window.addEventListener(WINDOW_CHANGE_EVENT, closeMenu);

u.ready(function () {
  // "More" dropdown
  u.sub('.dropdown-toggle.moremenu', 'click', function (e) {
    var hsubs = document.getElementById('hiddensubs');
    if (hsubs.style.display != 'none' && hsubs.style.display) {
      hsubs.style.display = 'none';
      console.log('noned')
      return
    }
    var w = document.querySelector('.th-subbar ul').clientWidth
    var x = ''
    u.each('#topbar li', function (e) {
      var p = { left: e.offsetLeft, top: e.offsetTop };
      var pl = p.left + e.clientWidth;
      if (pl < 0 - (e.clientWidth - 30) || pl > w) {
        x = x + '<li>' + e.innerHTML + '</li>'
      }
    });
    document.getElementById('hiddensubs').innerHTML = x;
    hsubs.style.display = 'inline-flex';

    var lastChild = hsubs.children[hsubs.children.length - 1];
    var newWidth = lastChild.offsetLeft + lastChild.offsetWidth + 15;
    hsubs.style.width = newWidth + 'px';
  });
  u.addEventForChild(document, 'click', '*', function (e, qelem) {
    var hsubs = document.getElementById('hiddensubs');
    var hdrop = document.querySelector('.dropdown-toggle.moremenu');
    if (hsubs.style.display != 'none' && hsubs.style.display) {
      // i hate this.
      if (qelem != hsubs && qelem.parentNode != hsubs && qelem != hdrop && qelem != hdrop.parentNode && qelem.parentNode != hdrop && qelem.parentNode.parentNode != hdrop && qelem.parentNode.parentNode.parentNode != hdrop) {
        console.log(qelem, hsubs)
        hsubs.style.display = 'none';
      }
    }
  })
  // Sort by menu
  u.sub('.sortmenu', 'click', function (e) {
    var sortby = document.getElementById('sortby');
    if (sortby.style.display != 'none' && sortby.style.display) {
      sortby.style.display = 'none';
      return
    }
    sortby.style.display = 'inline-flex';
  });
  // chat
  if (document.getElementById('chcont')) {
    socket.emit('subscribe', { target: 'chat' });
    socket.emit('getchatbacklog');
  }

  // Spoiler tags
  u.addEventForChild(document, 'click', 'spoiler', function (e, target) {
    target.classList.add("shown");
  });
})

u.ready(function () {
  u.each('*[data-timefmt]', function (el, i) {
    var fmt = el.getAttribute('data-timefmt');
    if (el.innerHTML == 'None') {
      el.innerHTML = _("Never");
    } else {
      var d = new Date(el.innerHTML);
      if (fmt == 'datetime') {
        el.innerHTML = d.toISOString().replace(/T/, ' ').replace(/\..+/, '');
      }
    }
  })
});

new Konami(function () {
  var d = new Date();
  d.setTime(d.getTime() + (365 * 24 * 60 * 60 * 1000)); //365 days
  var expires = "expires=" + d.toGMTString();
  if (document.getElementById('chpop')) document.getElementById('chpop').style.display = 'block'
  document.cookie = "dayNight" + "=" + "dank" + "; " + expires + ";path=/";
  document.getElementsByTagName('body')[0].classList.add('dark');
  document.getElementsByTagName('body')[0].classList.add('dank');
  document.querySelector('#toggledark span').innerHTML = icons.sun;
});

u.ready(function () {
  if (document.getElementById("throat-chat")) {
    window.chat = "true";
  }

  if (document.getElementById("pagefoot-oindex")) {
    window.oindex = "true";
  }

  if (document.getElementById("pagefoot-labrat")) {
    window.blocked = document.getElementById("pagefoot-blocked");
    if (window.blocked) {
      window.blocked = window.blocked.getAttribute('data-value');
      console.log("Blocked=", window.blocked)
      window.nposts = '/all/new';
    }
  }

  u.addEventForChild(document, 'submit', '#post-form', function (e, target) {
    document.getElementById('btn-sending').setAttribute('disabled', true);
  })

  u.addEventForChild(document, 'click', '#btn-sending', function (e, target) {
    window.sending = true;
  })

  u.addEventForChild(document, 'click', '#banuser-button', function (e, target) {
    if (confirm(_('Are you sure you want to ban this user?'))) {
      document.getElementById('banuser').submit();
    }
  })
  u.addEventForChild(document, 'click', '#wipevotes-button', function (e, target) {
    if (confirm(_('Are you sure you want to remove all the votes issued by this user?'))) {
      document.getElementById('wipevotes').submit();
    }
  })
  u.addEventForChild(document, 'click', '#unbanuser-button', function (e, target) {
    if (confirm(_('Are you sure you want to unban this user?'))) {
      document.getElementById('unbanuser').submit();
    }
  })
})

function fathomLoaded() {
  if (window.fathom != null) {
    if (document.getElementById('unameb') != null) {
      window.fathom.trackGoal('I4AFXZB3', 0);
    }
  } else {
    setTimeout(fathomLoaded, 1000);
  }
}

window.onload = function () {
  fathomLoaded();
}


window.onbeforeunload = function (e) {
  var flag = false;

  u.each('.exalert', function (e) {
    if (e.value !== '') {
      flag = true;
    }
  })
  if (e.target.activeElement.tagName == 'INPUT') flag = false;
  if (flag && !window.sending) {
    return 'Sure?';
  }
};


u.addEventForChild(document, 'click', 'img.alimg', function (e, qelem) {
  qelem.parentNode.removeChild(qelem);
})


u.addEventForChild(document, 'click', '.nsfw-blur', function (e, qelem) {
  e.preventDefault();
  qelem.classList.remove('nsfw-blur');
})


u.addEventForChild(document, 'click', '.block-blur', function (e, qelem) {
  e.preventDefault();
  qelem.classList.remove('block-blur');
})


// User blocks and ignores.
// Show the Save changes button when any change is made to the select inputs.
u.addEventForChild(document, 'input', '.ignore-form-select', function (e, qelem) {
  const uid = qelem.id.substring("ign-msg-".length);
  const saveButton = document.getElementById('submit-ignore-' + uid);
  saveButton.classList.remove('hide');
  saveButton.innerHTML = _('Save changes')
})

// Make same origin iframes change height to fit content.
u.ready(function () {
  setInterval(function () {
    var funding = document.getElementById('funding');
    if (funding) {
      if (funding.contentWindow.document.body.scrollHeight > 0 &&
        funding.style.height != funding.contentWindow.document.body.scrollHeight + 'px') {
        funding.style.height = 0;  // force recalculation
        funding.style.height = funding.contentWindow.document.body.scrollHeight + 'px';
      }
    }
  }, 100);
})
